import { render, staticRenderFns } from "./v-main.vue?vue&type=template&id=29db3466&scoped=true"
import script from "./v-main.vue?vue&type=script&lang=js"
export * from "./v-main.vue?vue&type=script&lang=js"
import style0 from "./v-main.vue?vue&type=style&index=0&id=29db3466&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29db3466",
  null
  
)

export default component.exports